import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';

const NotFoundPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms
        title="404 Error Page"
        titleTemplate={`%s${titleSuffix}`}
      />
      <main>
        <Banner
          overline="404 Error Page"
          heading="The page you’re looking for can’t be found"
          link={{
            text: 'Go Back Home',
            page: {
              slug: '/',
            },
          }}
        />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
